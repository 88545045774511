const events = {};
let initialEventsReplayed = false;

const eventInvokers = {
  click: ({ x, y }) => {
    var ev = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      pageX: x,
      pageY: y,
    });

    const el = document.elementFromPoint(x, y);
    if (el?.dispatchEvent) el.dispatchEvent(ev);
  },
  focus: (event) => {
    const el = document.getElementById(event.target.id);
    if (el.blur && el.focus) {
      el.blur();
      el.focus();
    }
  },
};

export const collectEvent = (type, eventObj) => {
  events[type] = events[type] || [];
  events[type].push(eventObj);
};

export const replayEvents = () => {
  //Prevent recursive invocation
  if (initialEventsReplayed) return;
  if (!events?.click) return;

  Object.entries(events).forEach((eventTypeArr) => {
    const [eventType, events] = eventTypeArr;
    if (!eventInvokers[eventType]) return;

    events.forEach((event) => {
      eventInvokers[eventType](event);
    });
  });

  initialEventsReplayed = true;
};
