import { collectEvent } from './eventCollector';

//step 5: this method should invoke hydrate method
let startHydration = () => {};
let hydrationEnqueued = false;

//step 6: remove our pre hydration event listeners
//step 7: enqueued click events will be replayed by eventCollector.replayEvents
const removeEventListeners = () => {
  document.querySelector('body').removeEventListener('focus', collectEventWrapper, true);
  document.querySelector('body').removeEventListener('click', hydrationWithEventCollector, true);
  window.removeEventListener('mousemove', initiateHydrationProcess);
  window.removeEventListener('scroll', initiateHydrationProcess);
};

//step 2: event listeners which will enqueue hydration
const applyEventListeners = () => {
  document.querySelector('body').addEventListener('focus', collectEventWrapper, true);
  document.querySelector('body').addEventListener('click', hydrationWithEventCollector, true);
  window.addEventListener('mousemove', initiateHydrationProcess);
  window.addEventListener('scroll', initiateHydrationProcess);
};

//step 3: wrapper for initiateHydrationProcess for click event listener
const hydrationWithEventCollector = (e) => {
  //Prevent current click handlers (i.e. a links) from execution
  e.preventDefault();
  //Collect click events
  collectEvent(e.type, e);
  //Defer click events execution
  initiateHydrationProcess(e);
};

//step 3: self descriptive
const collectEventWrapper = (e) => collectEvent(e.type, e);

//step 4: enqueue task in event queue to invoke hydration
//This way our event handler won't block further events execution
const enqueueHydration = () => {
  setTimeout(() => {
    startHydration();
    localStorage.setItem('deferredHydrationDisabled', true);
  }, 100);
};

//step 3: self descriptive
const initiateHydrationProcess = () => {
  if (!hydrationEnqueued) {
    enqueueHydration();
    hydrationEnqueued = true;
  }
};

//step 1: set hydration method and set event listeners which will enqueue hydration
export const initDeferredHydration = (invokeHydration) => {
  startHydration = invokeHydration;
  applyEventListeners();
};

export const cleanHydrationEventListeners = removeEventListeners;
